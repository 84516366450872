
import { defineComponent } from 'vue';
import { useInject } from '@/utils/inject';
import { useGetters } from '@/store/mixin';
import SearchTable from '@/components/table/SearchTable.vue';

/**
 * 激活返现
 */
export default defineComponent({
  name: 'ActivateReward',
  components: { SearchTable },
  setup() {
    const { https, mapper } = useInject();
    const { userMeta, isAgent } = useGetters();

    const tableOpts = {
      search: {
        criteria: {
          cols: [
            {
              field: 'sn',
              label: '机具号'
            },
            {
              field: 'agentId',
              label: '归属代理',
              type: 'agent',
              visible: () => !isAgent,
              defVal: () => (isAgent ? userMeta.uid : '')
            },
            {
              field: 'backerNo',
              label: '品牌号',
              type: 'select',
              options: mapper?.device.backerNo
            },
          ]
        },
        remote: {
          action: https?.market.getActivateReward
        }
      },
      cols: [
        {
          field: 'rewardSerno',
          label: '返现记录',
          ability: 'copy',
          width: 240
        },
        {
          field: 'sn',
          label: '机具号',
          ability: 'copy'
        },
        {
          field: 'backerNo',
          label: '品牌号',
          options: mapper?.device.backerNo
        },
        {
          field: 'uid',
          label: '用户ID'
        },
        {
          field: 'agentId',
          label: '归属代理ID',
          ability: 'copy'
        },
        {
          field: 'rewardId',
          label: '返现代理ID',
          ability: 'copy'
        },
        {
          field: 'rewardAmt',
          label: '返现金额'
        },
        {
          field: 'rewardTime',
          label: '达标时间'
        },
        {
          field: 'crtTime',
          label: '创建时间'
        },
        {
          field: 'updTime',
          label: '更新时间'
        }
      ]
    };

    return { tableOpts };
  }
});
